@mixin antialias() {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
@mixin hyphens($value) {
  hyphens: $value;
  -moz-hyphens: $value;
  -ms-hyphens: $value;
  -webkit-hyphens: $value;
}
@mixin underline($size, $color) {
  background-image: linear-gradient(transparent, transparent $size, $color $size, $color);
  background-position: bottom;
  background-size: 100% 6px;
  background-repeat: repeat-x;
}
@mixin no-select() {
  user-select: none;
  -khtml-user-select: none;
}